<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Company Amendments</a></li>
    </ol>
    <h1 class="page-header">COMPANY AMENDMENTS</h1>
    <panel noButton="true" title="COMPANY AMENDMENTS">
      <template v-if="is_loaded">
        <div
            class="row mb-3"
        >
          <div class="input-group col-md-4">
            <v-select
                v-model="company_id"
                :options="companyOptions"
                label="name"
                :reduce="g => g.company_unique_id"
                class="w-75"
                placeholder="Select Company"
            />
          </div>
        </div>
        <table
            v-if="companies.length"
            class="table table-striped table-responsive-sm"
        >
          <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Original</th>
            <th>Changes</th>
            <th>User</th>
            <th>Date</th>
          </tr>
          </thead>
          <tbody>
          <template>
            <tr
                v-for="(company, i) in companies"
                :key="i"
            >
              <td>{{ i + pagination.from }}</td>
              <td>{{ company.company.name }}</td>
              <td>
                <div
                    v-for="(value,k) in
                    company.original"
                    :key="k"
                >
                  <span>{{ k }} : </span>
                  <span>{{ value }}</span>
                </div>
              </td>
              <td>
                <div
                    v-for="(value,k) in
                    company.changes"
                    :key="k"
                >
                  <span>{{ k }} : </span>
                  <span>{{ value }}</span>
                </div>
              </td>
              <td>{{ company.username }}</td>
              <td>{{ company.created_at }}</td>
            </tr>
          </template>
          </tbody>
        </table>
        <div
            v-else
            class="alert alert-warning"
        >
          <p>There are no company amendments</p>
        </div>
        <pagination
            v-if="pagination.last_page > 1"
            :pagination="pagination"
            :offset="5"
            @paginate="fetchAmendments"
        />
      </template>
      <loading v-else />
    </panel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company_id: null,
      companies: [],
      companyOptions: [],
      is_loaded: false,
      pagination: {
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  watch: {
    company_id(o, n) {
      if (!n && !o) return;
      this.fetchEntriesDebounced();
    },
  },
  mounted() {
    this.fetchCompanyOptions();
    this.fetchAmendments();
  },
  methods: {
    fetchCompanyOptions() {
      this.$axios.get('/api/v1/companies/approved/options').then(response => {
        this.companyOptions = response.data;
      });
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetchAmendments()
      }, 2000)
    },
    fetchAmendments() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/companies/amendments', {
        params: {
          page: this.pagination.current_page,
          company_id: this.company_id
        }
      }).then(response => {
        this.is_loaded = true;
        if (!response.data.data) {
          this.companies = [];
          return;
        }
        this.companies = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
    }
  }
}
</script>